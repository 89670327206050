import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = [ "pick-up-address" ]
  static targets = [ "recommended" ]

  connect() {
    this.pickUpAddressOutlet.element.addEventListener("turbo:frame-render", (event) => {
      if (event.target.id == "pick_up_address" || event.detail.fetchResponse.redirected == true) {
        this.recommendedTarget.reload()
      }
    })
  }
}
