import { dom, library } from "@fortawesome/fontawesome-svg-core"

import * as regular from "@/packs/libs/font-awesome/regular"
import * as solid from "@/packs/libs/font-awesome/solid"
import * as light from "@/packs/libs/font-awesome/light"
import * as brands from "@/packs/libs/font-awesome/brands"

library.add(regular, solid, light, brands)

dom.watch({
  observeMutationsRoot: document.documentElement,
})
