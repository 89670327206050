import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { startDelay: { type: Number, default: 2000 }, showDuration: { type: Number, default: 20000 } }

  connect() {
    setTimeout(() => this.show(), this.startDelayValue)
  }

  show() {
    this.element.classList.remove("hidden")
    setTimeout(() => this.hide(), this.showDurationValue)
  }

  hide() {
    this.element.classList.add("hidden")
  }
}
