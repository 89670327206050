import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "address1", "address2", "suburb", "state", "postcode", "country"]

  connect() {
    if (window.config.addressFinderDisabled) return

    window.AddressFinder ? this.initAddressFinder() : this.loadAddressFinder()
  }

  disconnect() {
    document.querySelector("ul.af_list").remove()
  }

  loadAddressFinder() {
    let script = document.createElement("script")
    script.setAttribute("src", "https://api.addressfinder.io/assets/v3/widget.js")
    script.setAttribute("async", true)

    script.onload = () => {
      this.initAddressFinder()
    }
    document.head.appendChild(script)
  }

  initAddressFinder() {
    const key = window.config.addressFinderKey

    this.addressFinderWidget = new window.AddressFinder.Widget(this.inputTarget, key, "AU", {
      address_params: {},
    })

    this.addressFinderWidget.on("result:select", (fullAddress, metadata) => {
      const responseAddress = metadata.address || metadata

      this.address1Target.value = responseAddress.address_line_1 || ""
      this.address2Target.value = responseAddress.address_line_2 || ""
      this.suburbTarget.value = responseAddress.locality_name || responseAddress.city || ""
      this.stateTarget.value = responseAddress.state_territory || responseAddress.state || ""
      this.postcodeTarget.value = responseAddress.postcode || ""
      this.countryTarget.value = responseAddress.country_code || "AU"
    })

    document.querySelector("ul.af_list").classList.add("bt-af-list")
    document.querySelector("ul.af_list").style.width = `${this.inputTarget.clientWidth}px`
  }
}
