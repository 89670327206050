import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"
const MAX_SEARCH_NUMBER = 3
export default class Search extends Controller {
  static targets = ["input", "form", "dropdown", "clear"]

  connect() {
    // // set current term if present in the URL
    const searchTerm = new URLSearchParams(window.location.search).get("term")
    this.inputTarget.value = decodeURIComponent(searchTerm || "")
    this.overlay = document.querySelector(".search-overlay")
    this.dispatch("scroll", { detail: { disable: false }, bubbles: true })
    useClickOutside(this, this.formTarget)
  }

  clickOutside(e) {
    this.hideDropdown()
    this.hideOverlay()
    this.dispatch("scroll", { detail: { disable: false }, bubbles: true })
  }

  open() {
    this.dispatch("scroll", { detail: { disable: true }, bubbles: true })
    this.showDropdown()
    this.dropdownTarget.scrollTop = 0
    this.showOverlay()
  }

  hideDropdown() {
    this.dropdownTarget.classList.remove("visible")
  }

  showDropdown() {
    this.dropdownTarget.classList.add("visible")
  }

  hideOverlay() {
    this.overlay.classList.remove("search-overlay-on")
  }

  showOverlay() {
    this.overlay.classList.add("search-overlay-on")
  }

  saveRecentSearch(query) {
    // Save a search query to localStorage and limit the number of saved searches
    let searchlist = JSON.parse(localStorage.getItem("recentSearches")) || []
    if (!searchlist.includes(query)) {
      searchlist.unshift(query)
    }

    if (searchlist.length > MAX_SEARCH_NUMBER) {
      // Ensure that only the last MAX_SEARCH_NUMBER of entries are kept
      searchlist = searchlist.slice(0, MAX_SEARCH_NUMBER)
    }
    localStorage.setItem("recentSearches", JSON.stringify(searchlist))
  }

  submit() {
    if (!this.inputTarget.value) {
      this.dropdownTarget.classList.add("hidden")
    }
    const query = this.inputTarget.value.trim()
    if (query) {
      this.saveRecentSearch(query)
    }
    const encodedQuery = encodeURIComponent(query)
    Turbo.visit(`/artworks/search/${encodedQuery}?term=${encodedQuery}`)
  }

  clear() {
    // Clear the search input field and toggle the visibility of the clear button
    this.inputTarget.value = ""
  }
}
